import React, {Component} from 'react';
import {Textfit} from "react-textfit";
import '../../styles/css/ProgressButton.css';

class AnswerItemsComponent extends Component {

    componentDidMount() {
        this.setState({
            containers: document.getElementsByClassName('grid-item')
        })
    }

    render() {
        const itemsToIterate = this.props.answers || [];
        const question = this.props.question || {};
        const tenantVariables = this.props.tenantVariables || {};
        const fromScoreboard = this.props.fromScoreboard || false;
        const lastPickId = this.props.lastPickId || "";
        let highNumber = 0;
        for(const itemsIndex in itemsToIterate){
            const itemCount = itemsToIterate[itemsIndex].count;
            if(itemCount > highNumber){
                highNumber = itemCount;
            }
        }
        return (
            <div className="grid-container user" style={{gridAutoFlow:"row", transform:"translateY(0%)", padding:0, justifyItems: !this.props.sideBigScreen && "center"}}>
                {
                    itemsToIterate.map(function(item,i){
                        let container;
                        if(this.state && this.state.containers){
                            container = document.getElementsByClassName('grid-item')[0];
                        }
                        let progressWidth = "0";
                        let progressBarColor = tenantVariables.answerProgressBarClient || "transparent";
                        let backgroundColor='transparent';
                        let scoreboardBackgroundColor = tenantVariables.answerBackgroundColor || 'transparent';
                        let answerTextColor = tenantVariables.answerTextColor || "transparent";
                        let answerBorderColor = tenantVariables.answerOutlineColor || "transparent";
                        let progressBarOutlineScoreboardColor = tenantVariables.progressBarOutlineScoreboardColor || "transparent";
                        let show_percentages = this.props.show_percentages;
                        let showRightOrWrong= false;
                        let responseText = "";
                        let totalCount = this.props.totalCount || 0;
                        let totalAnswers = item.count || 0;
                        let percent = "0%";
                        if(container && totalCount>0){
                            progressWidth = parseInt(container.clientWidth * totalAnswers/totalCount, 10)
                        }
                        if(fromScoreboard){
                            progressBarColor = tenantVariables.progressBarColor || "transparent";
                            answerTextColor = tenantVariables.progressBarTextColor || "transparent";
                            scoreboardBackgroundColor = tenantVariables.progressBarBackgroundColor || "transparent";
                            progressBarOutlineScoreboardColor = progressBarOutlineScoreboardColor || "transparent";
                            if(highNumber === item.count && item.count > 0 && this.props.gameover){
                                scoreboardBackgroundColor = tenantVariables.progressBarEndWinColor || "transparent";
                            }
                        } else if(tenantVariables.lastVoteColor && !fromScoreboard && lastPickId && (lastPickId === item.id || lastPickId === item.key)) {
                            answerBorderColor = tenantVariables.lastVoteColor;
                        }
                        if(highNumber === item.count && item.count > 0 && this.props.gameover){
                            scoreboardBackgroundColor = tenantVariables.correctTextColor;
                            progressBarColor = "transparent";
                        }
                        if(totalCount > 0){
                            percent = (parseInt(totalAnswers/totalCount*100,10)).toString()+"%";
                        }
                        return <div
                                key={i}
                                id={item.id}
                                className="grid-item answerItemClass"
                                onClick={()=>this.props.onClickAnswer(item, i)}
                                style={{background: scoreboardBackgroundColor, color:answerTextColor, boxShadow:"unset", outline:"3px solid "+(fromScoreboard?progressBarOutlineScoreboardColor:answerBorderColor), display:"flex", justifyContent:'start', maxWidth: this.props.sideBigScreen?"none":800,minHeight:fromScoreboard?100:80, minWidth:280, lineHeight:1.4,border:"none",padding:10,borderRadius:0,zIndex:-2, width: "100%"}}>
                                <div className="progress-button-container" id="progress-button-container" style={{overflow:"visible", display: show_percentages ?"":"none"}}>
                                    <div className="progress-container" style={{margin: -10, width:progressWidth, backgroundColor:progressBarColor, zIndex:-1, borderRadius:0}}/>
                                </div>
                                <div style={{display:"grid", gridTemplateColumns:item.answerImage?"repeat(1,1fr 10fr 1fr)":"repeat(1,10fr 1fr)", gridGap:5, alignItems: "center", textAlign:"left", width:"100%"}}>
                                    <img src={item.answerImage} width={fromScoreboard?"100px":"58px"} height={fromScoreboard?"100px":"58px"} alt="" style={{marginLeft:5, display:item.answerImage?"":"none"}}/>
                                    <Textfit mode="multi" min={fromScoreboard?34:12} max={fromScoreboard?60:item.answerImage?32:40} style={{height:fromScoreboard?90:60,alignItems:"center",display:"flex",paddingLeft:5, paddingRight: 5}}>
                                        {item.answerText}
                                    </Textfit>
                                    {showRightOrWrong && !fromScoreboard ? (
                                        <span style={{backgroundColor:backgroundColor, height:80, width:80,display:"flex",alignItems:"center",textAlign:"center",justifyContent:"center",fontSize:40, margin: -10}}>
                                        {responseText}
                                    </span>
                                    ) : (
                                        <span style={{visibility:show_percentages ? "visible" : "hidden"}}>
                                      <div className="col-md-12" style={{padding:0, fontSize:12, display:fromScoreboard?"none":"", textAlign:"center"}}>
                                        <span>Fans Picked</span>
                                      </div>
                                      <div className="col-md-12" style={{padding:0, textAlign:fromScoreboard?"right":"center"}}>
                                        <span style={{fontSize:"1.5em", width:"100%", padding:5}}>{percent}</span>
                                      </div>
                                    </span>
                                    )
                                    }
                                </div>
                            </div>
                    }, this)
                }
            </div>
        );
    }
}

export default AnswerItemsComponent;
